<template>
  <div>
    <multiselect
      multiple
      :close-on-select="false"
      :disabled="null == selectedDistrict"
      :value="selectedDataSourceType"
      :searchable="true"
      placeholder="Select Data Source Type"
      label="name"
      track-by="id"
      :options="options"
      @input="saveUserChoice"
      @open="openSelector"
    >
      <template slot="noOptions">
        <span v-if="loader">
          Loading...
        </span>
        <span v-else>
          List is empty.
        </span>
      </template>
    </multiselect>
  </div>
</template>

<script>
export default {
  name: 'SelectDataSourceType',
  data () {
    return {
      loader: false,
      options: []
    }
  },
  computed: {
    selectedDataSourceType () {
      return this.$store.getters['filters/getSelectedSourceType']
    },
    selectedDistrict () {
      return this.$store.getters['filters/getSelectedDistrict']
    }
  },
  methods: {
    saveUserChoice (value) {
      this.$store.commit('filters/selectSourceType', value)
    },
    openSelector () {
      this.loader = true
      this.$store.commit('setLoader', true)
      this.$store.dispatch('filters/getDataSourceTypeList', this.selectedDistrict)
        .then(res => {
          this.options = res
          this.$store.commit('setLoader', false)
          this.loader = false
        })
    }
  }
}
</script>

<style scoped>

</style>
