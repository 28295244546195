<template>
    <div class="mt-3">
      <multiselect
          :value="selectedDistrict"
          :searchable="true"
          placeholder="Select District"
          label="name"
          track-by="id"
          :options="options"
          @input="saveUserChoice"
          :customLabel="showLabel"
          @open="openSelector"
      >
        <template slot="noOptions">
          <span v-if="loader">
              Loading...
          </span>
          <span v-else>
              List is empty.
          </span>
        </template>
      </multiselect>
    </div>
</template>

<script>
export default {
  name: 'SelectDistrict',
  data () {
    return {
      loader: false,
      options: []
    }
  },
  computed: {
    selectedDistrict () {
      return this.$store.getters['filters/getSelectedDistrict']
    }
  },
  methods: {
    showLabel (district) {
      return `${district.name} - ${district.state}`
    },
    saveUserChoice (value) {
      this.$store.commit('filters/selectDistrict', value)
    },
    openSelector () {
      this.loader = true
      this.$store.commit('setLoader', this.loader)
      this.$store.dispatch('filters/getDistricts')
        .then((res) => {
          this.options = res
          this.loader = false
          this.$store.commit('setLoader', this.loader)
        })
    }
  }
}
</script>

<style scoped>

</style>
