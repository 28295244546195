<template>
  <div class="input-group mb-3">
    <label
      v-if="!selectedFile"
      class="file-select active-cursor"
    >
      <div class="select-button">
        <!-- Add the style and icon you want -->
        <span>
          <i class="fa-solid fa-paperclip" />
          Attach File
        </span>
      </div>
      <input
        type="file"
        @change="handleFileChange"
        hidden
        ref="file"
      />
    </label>
    <div v-if="selectedFile" class="text-center">
      <div class="text-center">
        <p class="file-item">
          <span>{{ selectedFile.name }}</span>
          <i class="fas fa-times" @click="removeFile" />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadFile',
  computed: {
    selectedFile () {
      return this.$store.getters['filters/getProcessFile'] ? this.$store.getters['filters/getProcessFile'].get('file') : null
    }
  },
  methods: {
    handleFileChange () {
      const uploadFile = this.$refs.file.files[0]
      const FileStorage = new FormData()
      FileStorage.append('file', uploadFile)
      this.$store.commit('filters/setProcessFile', FileStorage)
    },
    removeFile () {
      this.$store.commit('filters/setProcessFile', null)
    }
  }
}
</script>

<style scoped>

</style>
