<template>
  <div>
    <multiselect
      :value="selectedYear"
      :searchable="true"
      placeholder="Select year"
      label="name"
      track-by="id"
      @input="saveUserChoice"
      :options="options"
    />
  </div>
</template>

<script>
export default {
  name: 'SelectYear',
  data () {
    return {
      options: []
    }
  },
  mounted() {
    this.setStartYears()
  },
  computed: {
    selectedYear () {
      return this.$store.getters['filters/getSelectedYear']
    }
  },
  methods: {
    setStartYears () {
      const currentYear = new Date().getFullYear()
      // var result = currentYear - 1;
      for(let i = currentYear; i >= 2015; i--) {
        this.options.push({
          id: i,
          name: i
        })
      }
    },
    saveUserChoice (value) {
      this.$store.commit('filters/selectYear', value)
    }
  }
}
</script>

<style scoped>

</style>
