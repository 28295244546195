<template>
  <div>
    <Header></Header>

    <div class="content-wrapper">

      <LeftMenu></LeftMenu>
    <!--    <iframe src="https://tools-scimporter3.glimpsek12.com/"-->
    <!--            height=400px width=100%>-->
    <!--    </iframe>-->
    </div>
    <Footer></Footer>

  </div>
</template>

<script>
import {APP_ENV} from "../../.env";
import LeftMenu from "@/layouts/LeftMenu"

export default {
  name: 'Home',
  components: { LeftMenu },
  mounted () {
    // this.getUser()
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    const asid = parameters.get('asid');
    const auid = parameters.get('auid');
    // if (false === this.isValidSessionToken || 0 === Number(this.isValidSessionToken) || (null !== asid && null !== auid))
    if ('development' !== APP_ENV) {
      this.getValidationTokenStatus(asid, auid)
    }
  },
  computed: {
    isValidSessionToken () {
      return this.$store.getters['auth/getSessionTokenStatus']
    }
  },
  watch: {
    isValidSessionToken: {
      handler () {
        if (null !== this.isValidSessionToken && 0 === Number(this.isValidSessionToken) && 'development' !== APP_ENV) {
          this.$router.push({ name: 'NotLogged' })
        }
      },
      immediate: true
    }
  },
  methods: {
    getUser () {
      this.$store.dispatch('auth/getUserByToken', { token: this.$store.getters['getUserToken'] })
        .then(response => {
          this.$store.commit('setUser', response.data.data.user)
          localStorage.setItem('user', JSON.stringify(response.data.data.user))
        })
        .catch(err => console.log('Error', err))
    },
    getValidationTokenStatus (asid, auid) {
      this.$store.commit('setLoader', true)
      this.$store.dispatch('auth/getSessionTokenStatus', { asid, auid })
        .then((res) => {
          if (0 === res.isValid) {
            this.$router.push({ name: 'NotLogged' })
          }
          this.$store.commit('setLoader', false)
        })
        .catch(() => {
          this.$router.push({ name: 'NotLogged' })
          this.$store.commit('setLoader', false)
        })
    }
  }
}
</script>

<style scoped>

</style>
