<template>
  <div class="mt-3">
    <multiselect
        :value="selectedMeasurementType"
        :searchable="true"
        placeholder="Select Measurement Type"
        label="name"
        track-by="id"
        @input="saveUserChoice"
        :options="options"
        :disabled="null == selectedDistrict"
        @open="openSelector"
    >
      <template slot="noOptions">
          <span v-if="loader">
              Loading...
          </span>
        <span v-else>
              List is empty.
          </span>
      </template>
    </multiselect>
  </div>
</template>

<script>
export default {
  name: 'SelectMeasurementType',
  data () {
    return {
      selected: null,
      loader: false,
      options: []
    }
  },
  computed: {
    selectedMeasurementType () {
      return this.$store.getters['filters/getSelectedMeasurementType']
    },
    selectedDistrict () {
      return this.$store.getters['filters/getSelectedDistrict']
    }
  },
  methods: {
    saveUserChoice (value) {
      this.$store.commit('filters/selectMeasurementType', value)
    },
    openSelector () {
      this.loader = true
      this.$store.commit('setLoader', this.loader)
      this.$store.dispatch('filters/getMeasurementTypes', this.selectedDistrict)
        .then((res) => {
          this.options = res
          this.loader = false
          this.$store.commit('setLoader', this.loader)
        })
        .catch((error) => {
          this.loader = false
          this.$store.commit('setLoader', this.loader)
        })
    }
  }
}
</script>

<style scoped>

</style>
